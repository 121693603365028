.rwd002-gnav {
    @include media-up(lg) {
        margin-left: map-get($spacers, 3);
    }

    @include media-down(lg) {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        z-index: 100;
        background-color: transparent;
        transform: translateX(100%);
        transition: background-color 0.2s ease-in-out, transform 0s 0.5s;
    }

    &.is-active {
        // メニューが開いたときのオーバーレイ
        @include media-down(lg) {
            background-color: fade-out($black, 0.2);
            transform: translateX(0%);
            transition: background-color 0.2s ease-in-out, transform 0s 0s;
        }
    }

    &__inner {
        // 格納メニュー
        @include media-up(lg) {
            display: flex;
            align-items: center;
        }
        @include media-down(lg) {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            padding: $gnav-height-md map-get($spacers, 2) map-get($spacers, 2);
            width: calc(100vw - map-get($spacers, 5));
            transform: translateX(100%);
            transition: $transition-base;
            background-color: $white;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }

        @include media-down(md) {
            padding-top: $gnav-height-xs;
        }
    }

    .is-active &__inner {
        @include media-down(lg) {
            transform: translateX(0%);
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: stretch;
        flex-direction: column;

        @include media-up(lg) {
            margin-right: -0.75em;
            align-items: stretch;
            flex-direction: row;
        }
    }

    &__contact {
        display: flex;
        gap: 10px;
        @include media-up(lg) {
            margin-left: 22px;
        }
        @include media-down(lg) {
            margin-top: 15px;
        }
        @include media-down(sm) {
            flex-direction: column;
        }
        &__link {
            position: relative;
            transition: $transition-base;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 121px;
            height: 34px;
            font-size: calc(12 / 16 * $font-size-base);
            color: #fff !important;
            text-decoration: none !important;
            letter-spacing: 0.2em;
            border-radius: 50px;
            @include media-down(lg) {
                width: 50%;
                height: 50px;
                font-size: calc(15 / 16 * $font-size-base);
            }
            @include media-down(sm) {
                width: 100%;
            }
            span {
                padding-right: 22px;
                transform: translateX(10px);
            }
            &._guidance {
                font-family: 'Noto Sans JP', $font-family-base;
                @include media-up(lg) {
                    color: #55c8d1 !important;
                    background-color: #fff;
                }
                @include media-down(lg) {
                    color: #fff !important;
                    background-color: #2abac5;
                }
                @media (hover: hover) {
                    &:hover,
                    &:focus {
                        color: #fff !important;
                        background-color: #2a90c5;
                        span {
                            background: svgUrlFunc(
                                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.93 34.3"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><g id="_文字"><path class="cls-1" d="M22.14,18.87c2.78-1.84,4.62-5,4.62-8.58,0-5.68-4.62-10.29-10.29-10.29S6.17,4.62,6.17,10.29c0,3.58,1.84,6.73,4.62,8.58C4.5,21.19,0,27.22,0,34.3h2.74c0-7.57,6.16-13.72,13.72-13.72s13.72,6.16,13.72,13.72h2.74c0-7.08-4.5-13.12-10.79-15.44ZM16.47,17.84c-4.16,0-7.55-3.39-7.55-7.55s3.39-7.55,7.55-7.55,7.55,3.39,7.55,7.55-3.39,7.55-7.55,7.55Z"/></g></svg>'
                                )
                                no-repeat right center / 15px auto;
                        }
                    }
                }
                span {
                    @include media-up(lg) {
                        background: svgUrlFunc(
                                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14.59"><defs><style>.cls-1{fill:#55c8d1;stroke-width:0px;}</style></defs><path class="cls-1" d="M9.42,8.02c1.18-.78,1.96-2.13,1.96-3.65,0-2.41-1.96-4.38-4.38-4.38S2.63,1.96,2.63,4.38c0,1.52.78,2.86,1.96,3.65-2.67.99-4.59,3.55-4.59,6.56h1.17c0-3.22,2.62-5.83,5.83-5.83s5.83,2.62,5.83,5.83h1.17c0-3.01-1.91-5.58-4.59-6.56ZM7,7.59c-1.77,0-3.21-1.44-3.21-3.21s1.44-3.21,3.21-3.21,3.21,1.44,3.21,3.21-1.44,3.21-3.21,3.21Z"/></svg>'
                            )
                            no-repeat right center / 15px auto;
                    }
                    @include media-down(lg) {
                        background: svgUrlFunc(
                                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.93 34.3"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><g id="_文字"><path class="cls-1" d="M22.14,18.87c2.78-1.84,4.62-5,4.62-8.58,0-5.68-4.62-10.29-10.29-10.29S6.17,4.62,6.17,10.29c0,3.58,1.84,6.73,4.62,8.58C4.5,21.19,0,27.22,0,34.3h2.74c0-7.57,6.16-13.72,13.72-13.72s13.72,6.16,13.72,13.72h2.74c0-7.08-4.5-13.12-10.79-15.44ZM16.47,17.84c-4.16,0-7.55-3.39-7.55-7.55s3.39-7.55,7.55-7.55,7.55,3.39,7.55,7.55-3.39,7.55-7.55,7.55Z"/></g></svg>'
                            )
                            no-repeat right center / 15px auto;
                    }
                }
            }
            &._entry {
                font-family: 'LINESeedJP', $font-family-base;
                font-style: normal;
                // font-weight: 700;
                background-color: #ff6432;
                @media (hover: hover) {
                    &:hover,
                    &:focus {
                        background-color: #e83b2e;
                    }
                }
                span {
                    background: svgUrlFunc(
                            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.08 36.08"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2.35px;}</style></defs><g id="_文字"><line class="cls-1" x1="5.12" y1="8.66" x2="21.96" y2="8.66"/><line class="cls-1" x1="5.12" y1="13.65" x2="21.96" y2="13.65"/><line class="cls-1" x1="5.12" y1="18.63" x2="21.96" y2="18.63"/><line class="cls-1" x1="5.12" y1="23.62" x2="21.96" y2="23.62"/><polygon class="cls-1" points="1.18 34.9 1.18 1.18 25.91 1.18 25.91 28.51 19.46 34.9 1.18 34.9"/><polyline class="cls-1" points="19.46 34.9 19.46 28.36 25.91 28.36"/></g></svg>'
                        )
                        no-repeat right center / 12px auto;
                }
            }
        }
    }

    &__copyright {
        @include media-up(lg) {
            display: none;
        }
        @include media-down(lg) {
            font-size: $font-size-xs;
            color: tint($body-color, 50%);
        }
    }
}

.rwd002-gnav-sublist {
    // sitemap.phpなどのシステムメニュー
    list-style: none;
    padding: 0;
    @include media-up(lg) {
        display: none;
    }
    @include media-down(lg) {
        display: flex;
        flex-wrap: wrap;
        margin: map-get($spacers, 2) 0;
        color: tint($body-color, 50%);
        font-size: $font-size-xs;
        margin-left: -0.5em;
        margin-right: -0.5em;
        &__item {
            position: relative;
            padding: 0.2em 0.6em;
        }
        &__item + &__item:before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
            content: '';
            height: 1em;
            width: 1px;
            background-color: tint($body-color, 50%);
        }
    }
}

// グローバルナビ　Item(親)
.rwd002-gnav-item {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9375rem;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    font-feature-settings: 'palt';

    @include media-down(lg) {
        border: solid $gray-300;
        border-width: 1px 0;
        & + & {
            border-top-width: 0;
        }
    }

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        transition: $transition-base;
        padding: 0 15px;
        min-height: 3.3em;
        font-family: 'Noto Sans JP', $font-family-base;
        color: #fff !important;

        @media (max-width: 1150px) {
            padding: 0 8px;
        }

        @include media-up(lg) {
            justify-content: center;
            height: 100%;
            min-height: $gnav-height-md;
            font-size: calc(12 / 16 * $font-size-base);
            letter-spacing: 0.2em;
        }
        @include media-down(lg) {
            flex-grow: 1;
            flex-basis: calc(100% - 50px);
            padding: 0.25em 0 0.25em 1.5em;
            color: #373737 !important;
        }

        &:hover,
        &:focus,
        &.current {
            text-decoration: none;
        }

        // PC表示のメニュー装飾
        @include media-up(lg) {
            span {
                position: relative;
                padding-top: 0.5em;
                padding-bottom: 0.5em;

                &:after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 0;
                    height: 1px;
                    width: 0;
                    background-color: #fff;
                    transition: $transition-base;
                    opacity: 0;
                }
            }
            &:hover span:after,
            &:focus span:after {
                width: 100%;
                opacity: 1;
            }
        }

        // SP表示のメニュー装飾
        @include media-down(lg) {
            &:after {
                position: absolute;
                content: '';
                top: calc(50% - 0.3em);
                left: 0;
                width: 1.5em;
                height: 0.6em;
                background: center / contain no-repeat;
                background-image: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$body-color}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
                );
            }
        }
    }
}

// グローバルナビ subnav(子)

/* HTML構成例
<div class="rwd002-subnav">
  <div class="rwd002-subnav__inner">
    <ul class="rwd002-subnav__list">
      <li class="rwd002-subnav__item">
        <a href="" class="rwd002-subnav__link"><span>サブメニュー01</span></a>
      </li>
      <!-- /.rwd002-subnav__item -->
      <li class="rwd002-subnav__item">
        <a href="" class="rwd002-subnav__link"><span>サブメニュー02</span></a>
      </li>
      <!-- /.rwd002-subnav__item -->
      <li class="rwd002-subnav__item">
        <a href="" class="rwd002-subnav__link"><span>サブメニュー03</span></a>
      </li>
      <!-- /.rwd002-subnav__item -->
    </ul>
    <!-- /.rwd002-subnav__list -->
  </div>
  <!-- /.rwd002-subnav__inner -->
</div>
<!-- /.rwd002-subnav -->
*/

.rwd002-subnav {
    @include media-up(lg) {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        transform-origin: 0 0;
        transform: scale(1, 0);
        transition: transform 0.2s 0.2s ease-in-out;

        &__inner {
            background-color: $primary;
            color: $white;
        }

        &__btn {
            display: none;
        }

        .rwd002-gnav-item:hover & {
            transform: scale(1, 1);
        }

        &.collapse:not(.show) {
            display: block;
        }
    }
    @include media-down(lg) {
        flex-basis: 100%;
    }

    &__inner {
        @include media-up(lg) {
            margin: 0 auto;
        }
    }

    &__list {
        list-style: none;
        padding: 0 1.5em;

        @include media-up(lg) {
            display: flex;
            align-items: stretch;
            justify-content: center;
        }

        @include media-down(lg) {
            padding-right: 0;
            padding-bottom: 1em;
        }
    }

    &__tgl {
        @extend .btn;

        &:focus {
            box-shadow: none;
        }

        @include media-up(lg) {
            display: none;
        }
        @include media-down(lg) {
            position: relative;
            flex-basis: 50px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 1px;
                background-color: $primary;
                transition: transform 0.2s ease-in-out;
            }

            &:after {
                transform: rotate(-90deg);
            }

            &.is-active {
                &:before {
                    transform: rotate(180deg);
                }
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }
}
.rwd002-header .rwd002-subnav-item {
    &__link {
        display: block;
        transition: $transition-base;
        &:hover,
        &:focus {
            text-decoration: none;
            opacity: 0.8;
        }
        @include media-up(lg) {
            padding: 0.75em 1em;
        }
        @include media-down(lg) {
            padding: 0.2em 0;
        }
    }
}
