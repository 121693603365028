.rwd002-fixNav {
    position: fixed;
    transition: $transition-base;
    z-index: 2;
    display: none;

    @include media-up(lg) {
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        // top: min( calc(100vh - 235px), 70vh );
        // right: 0;
    }

    @include media-down(lg) {
        bottom: 0;
        align-items: stretch;
        width: 100%;
        height: 60px;
        z-index: 11;
        transition: all 0.2s ease-in-out;
        // transform: translateY(100%);
    }

    &.is-active {
        @include media-down(lg) {
            opacity: 1;
            display: flex;
            transform: translateY(0);
        }
    }

    a {
        transition: $transition-base;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        font-size: calc(14 / 16 * $font-size-base);
        text-decoration: none !important;
        color: #fff !important;
        letter-spacing: 0.2em;
        span {
          padding-right: 20px;
        }
    }

    &__guidance {
        font-family: 'Noto Sans JP', $font-family-base;
        background-color: #2abac5;
        @media (hover: hover) {
            &:hover,
            &:focus {
                background-color: #2a90c5;
            }
        }
        span {
          background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.93 34.3"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><g id="_文字"><path class="cls-1" d="M22.14,18.87c2.78-1.84,4.62-5,4.62-8.58,0-5.68-4.62-10.29-10.29-10.29S6.17,4.62,6.17,10.29c0,3.58,1.84,6.73,4.62,8.58C4.5,21.19,0,27.22,0,34.3h2.74c0-7.57,6.16-13.72,13.72-13.72s13.72,6.16,13.72,13.72h2.74c0-7.08-4.5-13.12-10.79-15.44ZM16.47,17.84c-4.16,0-7.55-3.39-7.55-7.55s3.39-7.55,7.55-7.55,7.55,3.39,7.55,7.55-3.39,7.55-7.55,7.55Z"/></g></svg>'
            )
            no-repeat right center / 15px auto;
        }
    }
    &__entry {
        font-family: 'LINESeedJP', $font-family-base;
        font-style: normal;
        background-color: #ff6432;
        @media (hover: hover) {
            &:hover,
            &:focus {
                background-color: #ff5435;
            }
        }
        span {
          background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.08 36.08"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2.35px;}</style></defs><g id="_文字"><line class="cls-1" x1="5.12" y1="8.66" x2="21.96" y2="8.66"/><line class="cls-1" x1="5.12" y1="13.65" x2="21.96" y2="13.65"/><line class="cls-1" x1="5.12" y1="18.63" x2="21.96" y2="18.63"/><line class="cls-1" x1="5.12" y1="23.62" x2="21.96" y2="23.62"/><polygon class="cls-1" points="1.18 34.9 1.18 1.18 25.91 1.18 25.91 28.51 19.46 34.9 1.18 34.9"/><polyline class="cls-1" points="19.46 34.9 19.46 28.36 25.91 28.36"/></g></svg>'
            )
            no-repeat right center / 12px auto;
        }
    }

    &__inquiry {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $primary;
        color: $white;
        text-align: center;
        font-weight: bold;
        font-size: $font-size-base;
        letter-spacing: 0.1em;
        transition: $transition-base;

        @include media-up(lg) {
            position: absolute;
            right: 0;
            width: 64px;
            height: 171px;
            flex-direction: column;
        }

        @include media-down(lg) {
            width: 100%;
            padding: 1rem;
        }

        span {
            @include media-up(lg) {
                writing-mode: vertical-rl;
            }
        }

        &:hover,
        &:focus {
            background-color: darken($primary, 10%);
            color: $white;
            text-decoration: none;
        }
    }

    &__pagetop {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $body-color;
        color: $white;
        font-family: 'Roboto', $font-family-sans-serif;
        transition: $transition-base;
        letter-spacing: 0.1em;
        text-indent: 0.1em;

        @include media-up(lg) {
            position: absolute;
            right: 0;
            bottom: -171px;
            width: 64px;
            height: 64px;
            transform: translateY(100%);
        }

        @include media-down(lg) {
            min-width: 64px;
            font-size: $font-size-sm;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: $white;
            background-color: darken($body-color, 10%);
        }
    }
}
