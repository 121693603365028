.is--home {
    .rwd002-block-freearea {
        margin: 0 !important;
        padding: 0 !important;
    }
    .rwd002-block-freearea__inner {
        margin: 0 !important;
        padding: 0 !important;
        max-width: inherit;
    }
    .rwd002-fixNav {
        @include media-down(lg) {
            display: flex;
        }
    }
    .rwd002-gnav__contact__link._guidance {
        color: #fff !important;
        background-color: #2abac5;
        @include media-up(lg) {
        }
        span {
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.93 34.3"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><g id="_文字"><path class="cls-1" d="M22.14,18.87c2.78-1.84,4.62-5,4.62-8.58,0-5.68-4.62-10.29-10.29-10.29S6.17,4.62,6.17,10.29c0,3.58,1.84,6.73,4.62,8.58C4.5,21.19,0,27.22,0,34.3h2.74c0-7.57,6.16-13.72,13.72-13.72s13.72,6.16,13.72,13.72h2.74c0-7.08-4.5-13.12-10.79-15.44ZM16.47,17.84c-4.16,0-7.55-3.39-7.55-7.55s3.39-7.55,7.55-7.55,7.55,3.39,7.55,7.55-3.39,7.55-7.55,7.55Z"/></g></svg>'
                )
                no-repeat right center / 15px auto;
        }
        @media (hover: hover) {
            &:hover,
            &:focus {
                background-color: #2a90c5;
            }
        }
    }
    .rwd002-container {
        padding-bottom: 0 !important;
    }
    .rwd002-footer {
        @include media-down(lg) {
            margin-bottom: 60px;
        }
    }
}
// * {
//     cursor: block !important;
//     @include media-up(lg) {
//         cursor: none !important;
//     }
// }
// .js-chaser,
// .js-cursor {
//     border-radius: 50%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     pointer-events: none;
//     @include media-down(lg) {
//         display: none;
//     }
// }
// .js-cursor {
//     width: 8px;
//     height: 8px;
//     background-color: #ffca5c;
//     z-index: 10001;
// }
// .js-chaser {
//     width: 20px;
//     height: 20px;
//     background-color: rgba(252, 212, 44, 0.5);
//     z-index: 10000;
//     transition: transform 0.3s ease-in-out;
//     @media (hover: hover) {
//         &.is-active {
//             transform: scale(4.5);
//         }
//     }
// }
.mainvisual {
    overflow: hidden;
    position: relative;
    width: 100%;
    @include media-up(lg) {
        height: 100svh;
    }
    @include media-down(lg) {
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-end;
        // align-items: center;
        // height: calc(100svh - 120px);
    }
    &__catch {
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        margin: auto;
        @include media-up(sm) {
            left: 10vw;
            top: 0;
            bottom: 0;
        }
        @include media-down(md) {
            bottom: 190px;
            left: 0;
            padding-inline: map-get($spacers, 3);
        }
        .text {
            @include media-up(sm) {
                margin: 15px 0 0 0;
            }
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__tagline {
        position: absolute;
        z-index: 1;
        right: 20px;
        display: flex;
        margin: auto;
        @include media-up(md) {
            top: 0;
            bottom: 0;
            align-items: center;
        }
        @include media-down(md) {
            top: 110px;
        }
        p {
            margin: 0;
            padding: 22px 4px;
            font-family: 'Noto Sans JP', $font-family-base;
            font-size: calc(11 / 16 * $font-size-base);
            writing-mode: vertical-rl;
            color: #fff;
            letter-spacing: 0.16em;
            border-top: solid 1px #fff;
            border-bottom: solid 1px #fff;
        }
    }

    &__scroll {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        writing-mode: vertical-rl;
        column-gap: 16px;
        color: $white;
        letter-spacing: 0.24em;
        font-family: 'LINESeedJP', $font-family-base;
        font-style: normal;
        font-size: calc(12 / 16 * $font-size-base);
        z-index: 1;
        @include media-up(lg) {
            padding: 0 map-get($spacers, 3);
        }
        @include media-down(lg) {
            bottom: 84px;
            left: 10px;
            column-gap: 10px;
        }
        @include media-down(md) {
            bottom: 110px;
        }
        .bar {
            position: relative;
            height: 70px;
            @include media-down(sm) {
                height: 55px;
            }
            &::before {
                content: '';
                position: absolute;
                top: -3px;
                left: 0;
                right: 0;
                width: 6px;
                height: 6px;
                margin: auto;
                background-color: #e8e631;
                border-radius: 50%;
                animation: scroll_line 1200ms ease-in-out infinite alternate;
                @include media-down(sm) {
                    animation: scroll_line_sp 1200ms ease-in-out infinite
                        alternate;
                }
            }
            &:after {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                bottom: 0;
                display: block;
                content: '';
                height: 100%;
                width: 1px;
                background: #fff;
            }
        }
    }
}
@keyframes scroll_line {
    from {
        transform: translateY(-3px);
    }
    to {
        transform: translateY(70px);
    }
}
@keyframes scroll_line_sp {
    from {
        transform: translateY(-3px);
    }
    to {
        transform: translateY(55px);
    }
}
.mainvisual-slider {
    max-height: 100vh;
    // .is-home-first-view & {
    //     max-height: 100vh;
    // }
    @include media-down(lg) {
        height: calc(100svh - 60px);
    }
    @include media-down(sm) {
        aspect-ratio: 750 / 1234;
        height: auto;
    }
    &__item img {
        width: 100%;
        height: 100svh;
        object-fit: cover;
        @include media-down(sm) {
            height: 100%;
        }
    }

    .swiper-slide-active img,
    .swiper-slide-duplicate-active img,
    .swiper-slide-prev img {
        transform: scale(1.1);
        animation: zoomOut 10s linear 0s 1 normal both;
    }
}
@keyframes zoomOut {
    from {
        transform: scale(1.1);
    }
    to {
        transform: scale(1);
    }
}
.mainvisual-headline {
    display: flex;
    align-items: center;
    z-index: 1;
    width: 100%;
    height: 84px;
    padding-inline: 35px;
    background-color: #fff;
    border-radius: 5px 0 0 0;
    @include media-up(lg) {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 618px;
    }
    @include media-down(lg) {
        position: relative;
        z-index: 1;
        background: none;
        border-bottom: solid 1px #e4e5e8;
    }
    @include media-down(md) {
        height: 110px;
        padding-inline: map-get($spacers, 3);
    }
    &__article {
        width: 100%;
    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        gap: 12px;
        white-space: nowrap;
        width: 100%;
        padding-right: 30px;
        // padding: 1.5em 55px 1.5em 25px;
        @include media-up(lg) {
            // column-gap: 12px;
            // grid-template-columns: auto auto 1fr;
        }
        @include media-down(lg) {
            column-gap: 18px;
            row-gap: 5px;
            padding-right: calc(map-get($spacers, 3) + 1em);
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto;
        }
        @include media-down(md) {
            column-gap: 15px;
        }
        @include media-down(sm) {
            flex-wrap: wrap;
        }
        &:hover,
        &:focus {
            text-decoration: none;
            .subject {
                text-decoration: underline;
                text-underline-offset: 0.2em;
                color: #384e7a;
            }
            .icon {
                &::before {
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
    .date {
        font-size: calc(13 / 16 * $font-size-base);
        font-family: 'Roboto', $font-family-base;
        font-weight: bold;
        letter-spacing: 0.04em;
        color: #373737;
    }

    .cate {
        padding: 5px 15px;
        font-size: calc(11 / 16 * $font-size-base);
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.14em;
        background-color: #384e7a;
        border-radius: 20px;
        @include media-down(sm) {
            padding: 3px 15px;
        }
    }

    .subject {
        transition: $transition-base;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: calc(13 / 16 * $font-size-base);
        letter-spacing: 0.14em;
        margin: 0;
        color: #373737;
        @include media-down(lg) {
            grid-column: 1 / span 2;
        }
        @include media-down(sm) {
            width: 100%;
            margin-top: 5px;
        }
    }
    .icon {
        transition: $transition-base;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 7px;
        height: 7px;
        margin: auto;
        background-color: #ffca5c;
        border-radius: 50%;
        @include media-down(sm) {
            top: inherit;
            bottom: 5px;
        }
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 22px;
            height: 22px;
            transition: $transition-base;
            background-color: #ffeea7;
            border-radius: 50%;
        }
    }
}
.block-about {
    position: relative;
    margin-top: 80px;
    padding: 80px map-get($spacers, 3) 150px;
    @include media-down(lg) {
        margin-top: 60px;
        padding: 50px map-get($spacers, 3) 90px;
    }
    &__inner {
        display: grid;
        gap: 12px;
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
        @include media-up(lg) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-down(lg) {
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }
    &__visual {
        @include media-down(lg) {
            display: flex;
            justify-content: center;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__title {
        @include c-block-title();
        @include media-down(lg) {
            align-items: center;
            margin-bottom: 20px;
        }
    }
    &__lead {
        margin-bottom: 15px;
        font-family: 'Noto Sans JP', $font-family-base;
        font-size: calc(26 / 16 * $font-size-base);
        font-weight: 700;
        color: #384e7a;
        letter-spacing: 0.04em;
        line-height: calc(48 / 26);
        @include media-down(lg) {
            text-align: center;
        }
        @include media-down(md) {
            font-size: calc(18 / 16 * $font-size-base);
            line-height: calc(28 / 18);
        }
    }
    &__text {
        margin-bottom: 30px;
        font-family: 'Noto Sans JP', $font-family-base;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: 500;
        font-feature-settings: 'palt';
        color: #373737;
        letter-spacing: 0.08em;
        line-height: calc(32 / 15);
        @include media-down(md) {
            line-height: calc(28 / 15);
        }
    }
    &__btnwrap {
        @include media-down(lg) {
            display: flex;
            justify-content: center;
        }
    }
    &__btn {
        @include c-button-view();
    }
}
.about-loop {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    background-image: url(/assets/img/webp/home/tl-about@2x.webp);
    background-repeat: repeat-x;
    background-size: auto 115px;
    animation: loop 60s linear infinite;
    @include media-down(md) {
        height: 75px;
        background-size: auto 72px;
    }
}
@keyframes loop {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1529px 0;
    }
}
.block-about-nav {
    display: grid;
    width: 100%;
    max-width: 960px;
    margin: 90px auto 0;
    @include media-down(md) {
        margin-top: 40px;
    }
    @include media-up(sm) {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
    }
    @include media-down(sm) {
        grid-template-columns: 1fr;
        gap: 7px;
    }
    &__link {
        transition: $transition-base;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        padding-right: 20px;
        text-decoration: none !important;
        color: #fff !important;
        border-radius: 3px;
        @include media-down(md) {
            height: 90px;
        }
        &._guidance {
            font-family: 'Noto Sans JP', $font-family-base;
            font-size: calc(20 / 16 * $font-size-base);
            font-weight: 500;
            letter-spacing: 0.2em;
            background-color: #2abac5;
            @include media-down(md) {
                ._icon {
                    width: 27px;
                    margin-right: 17px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            @media (hover: hover) {
                &:hover,
                &:focus {
                    background-color: #2a90c5;
                }
            }
        }
        &._entry {
            font-family: 'LINESeedJP', $font-family-base;
            font-style: normal;
            font-size: calc(22 / 16 * $font-size-base);
            letter-spacing: 0.2em;
            background-color: #ff6432;
            @include media-down(md) {
                font-size: calc(20 / 16 * $font-size-base);
                ._icon {
                    width: 20px;
                    margin-right: 20px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            @media (hover: hover) {
                &:hover,
                &:focus {
                    background-color: #e83b2e;
                }
            }
        }
        ._icon {
            display: flex;
            align-items: center;
            height: auto;
            margin-right: 25px;
        }
    }
}
.block-business {
    margin-bottom: 100px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-bottom: 40px;
    }
    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
    }
    &__list {
        display: grid;
        @include media-up(lg) {
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
        }
        @include media-down(lg) {
            grid-template-columns: 1fr;
            gap: 35px;
        }
    }
    &__item {
        transition: $transition-base;
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 415px;
        padding-top: 160px;
        text-decoration: none !important;
        background-color: #f3f7fc;
        border-radius: 3px;
        @include media-down(lg) {
            min-height: 365px;
            padding-top: 140px;
        }
        &:first-of-type {
            ._image {
                top: -40px;
                @include media-down(lg) {
                    top: -20px;
                    width: 151px;
                    height: auto;
                }
            }
        }
        &:last-of-type {
            ._image {
                top: -45px;
                @include media-down(lg) {
                    top: -25px;
                    width: 155px;
                    height: auto;
                }
            }
        }
        ._image {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
        }
        @media (hover: hover) {
            &:hover,
            &:focus {
                background-color: #ffb315;
                .block-business__title {
                    .eng {
                        color: #fff;
                    }
                }
                .block-business__btn {
                    color: #fff;
                }
                .icon {
                    &::before {
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }
    }
    &__title {
        @include c-block-title(center);
        transition: $transition-base;
        @include media-down(lg) {
            margin-bottom: 20px;
        }
    }
    &__text {
        font-family: 'Noto Sans JP', $font-family-base;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: 500;
        font-feature-settings: 'palt';
        text-align: center;
        color: #373737;
        letter-spacing: 0.08em;
        line-height: calc(28 / 15);
    }
    &__btn {
        transition: $transition-base;
        position: absolute;
        z-index: 1;
        right: 40px;
        bottom: 35px;
        margin: 0;
        padding-right: 30px;
        font-family: 'LINESeedJP', $font-family-base;
        font-style: normal;
        font-size: calc(13 / 16 * $font-size-base);
        font-weight: 700;
        text-decoration: none !important;
        color: #199f5d;
        letter-spacing: 0.08em;
        line-height: 1;
        @include media-down(lg) {
            right: 25px;
        }
        .icon {
            transition: $transition-base;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 7px;
            height: 7px;
            margin: auto;
            background-color: #ffca5c;
            border-radius: 50%;
            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 22px;
                height: 22px;
                transition: $transition-base;
                background-color: #ffeea7;
                border-radius: 50%;
            }
        }
    }
}
.block-guidance {
    padding: 0 map-get($spacers, 3);
    @include media-down(lg) {
        padding: 0 map-get($spacers, 3);
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        @include media-down(lg) {
            flex-direction: column-reverse;
            align-items: center;
        }
    }
    &__visual {
        @include media-down(md) {
            width: 200px;
            margin: 0 auto 10px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__body {
        width: 100%;
        max-width: 550px;
        flex-shrink: 0;
        @include media-up(lg) {
            padding-top: 17px;
        }
    }
    &__title {
        @include c-block-title();
        @include media-down(lg) {
            align-items: center;
            margin-bottom: 20px;
        }
    }
    &__lead {
        margin-bottom: 15px;
        font-family: 'Noto Sans JP', $font-family-base;
        font-size: calc(26 / 16 * $font-size-base);
        font-weight: 700;
        color: #384e7a;
        letter-spacing: 0.04em;
        line-height: calc(48 / 26);
        @include media-down(lg) {
            text-align: center;
        }
        @include media-down(md) {
            font-size: calc(18 / 16 * $font-size-base);
            line-height: calc(28 / 18);
        }
    }
    &__text {
        margin-bottom: 30px;
        font-family: 'Noto Sans JP', $font-family-base;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: 500;
        font-feature-settings: 'palt';
        color: #373737;
        letter-spacing: 0.08em;
        line-height: calc(32 / 15);
        @include media-down(md) {
            line-height: calc(28 / 15);
        }
    }
    &__btnwrap {
        @include media-down(lg) {
            display: flex;
            justify-content: center
        }
    }
    &__btn {
        @include c-button-view();
    }
}
.block-news {
    padding: 90px map-get($spacers, 3) 120px;
    @include media-down(md) {
        padding: 70px map-get($spacers, 3) 60px;
    }
    &__body {
        position: relative;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__row {
        display: flex;
        align-items: flex-start;
        @include media-down(lg) {
            display: block;
        }
    }
    &__head {
        width: 235px;
        margin-top: 20px;
        @include media-down(lg) {
            width: 100%;
            margin-top: 0;
        }
    }
    &__title {
        @include c-block-title();
        @include media-down(lg) {
            align-items: center;
            margin-bottom: 20px;
        }
    }
    &__list {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        &-wrap {
            width: calc(100% - 260px);
            @include media-down(lg) {
                width: 100%;
            }
        }
    }
    &__btnwrap {
        display: flex;
        @include media-up(lg) {
            justify-content: flex-end;
        }
        @include media-down(lg) {
            justify-content: center;
            margin-top: 25px;
        }
    }
    &__btn {
        @include c-button-view();
    }
}
.block-news-item {
    @include list-common__item();
    --item-new-color: $primary;
    position: relative;
    border-bottom: solid 1px #e4e5e8;
    &:last-child {
        border-bottom: none;
    }
    &__body {
        @include media-up(sm) {
            display: flex;
            align-items: center;
        }
    }
    &__link {
        position: relative;
        display: block;
        padding: 22px 0;
        color: #373737;
        transition: $transition-base;
        @include media-down(md) {
            padding: 18px 0;
        }
        &:hover,
        &:focus {
            color: $primary;
            text-decoration: none;
            .title {
                text-decoration: underline;
                color: #384e7a;
            }
        }
    }
    .title {
        transition: $transition-base;
        flex-grow: 1;
        font-size: calc(14 / 16 * $font-size-base);
        letter-spacing: 0.08em;
        color: inherit;
        @include media-down(md) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
        @include media-up(sm) {
            margin-left: 20px;
        }
        @include media-down(sm) {
            margin-top: 5px;
        }
        &.is-new:after {
            margin-left: 12px;
            font-size: calc(14 / 16 * $font-size-base);
            color: #003cb1;
            @include media-down(md) {
                nl: 10px;
            }
        }
    }
    .date {
        // width: 80px;
        margin: 0 7px 0 0;
        font-size: calc(13 / 16 * $font-size-base);
        font-family: 'Roboto', $font-family-base;
        font-weight: bold;
        letter-spacing: 0.08em;
        color: #373737;
    }
    .cate {
        min-width: 80px;
        margin: 0;
        padding: 3px 10px;
        font-size: calc(11 / 16 * $font-size-base);
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.08em;
        background-color: #384e7a;
        border-radius: 20px;
        @include media-down(sm) {
            padding: 2px 10px;
        }
    }
}
