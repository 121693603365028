.company-profile {
    margin-top: 70px;
    margin-bottom: 80px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-top: -30px;
        margin-bottom: 40px;
    }
    &__inner {
        position: relative;
        width: 100%;
        max-width: 800px;
        margin-inline: auto;
    }
    &__table {
        @include table-typeA();
    }
    ._text-right {
        margin-bottom: 0;
        font-size: calc(12 / 16 * $font-size-base);
        text-align: right;
        letter-spacing: 0.08em;
        @include media-up(md) {
          margin-top: -15px;
        }
    }
}
.company-map {
    &__inner {
        position: relative;
        width: 100%;
        max-width: 1000px;
        margin-inline: auto;
    }
    &-wrap {
        overflow: hidden;
        width: 100%;
        height: 540px;
        @include media-down(md) {
            height: 455px;
        }
    }
    iframe {
        transform: translateY(-160px);
        display: block;
        width: 100%;
        height: 900px;
        @include media-down(md) {
            height: 815px;
        }
    }
}
.company-access {
    @include media-up(lg) {
        padding-inline: 60px;
    }
    &__inner {
        position: relative;
        width: 100%;
        max-width: 1280px;
        display: flex;
        justify-content: flex-end;
        margin-inline: auto;
    }
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 243px;
        padding: 24px 24px 24px 65px;
        background-color: #2abac5;
        @include media-up(lg) {
            position: sticky;
            right: 0;
            bottom: 0;
            width: 534px;
            margin-top: -75px;
        }
        @include media-down(lg) {
            width: 100%;
        }
        @include media-down(md) {
            justify-content: center;
            height: 220px;
            padding: 0 map-get($spacers, 3);
        }
        ._title {
            margin-bottom: 10px;
            padding-left: 23px;
            font-family: 'LINESeedJP', $font-family-base;
            font-style: normal;
            font-size: calc(26 / 16 * $font-size-base);
            font-weight: 700;
            color: #fff;
            letter-spacing: 0.08em;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.71 12.72"><defs><style>.cls-1{fill:#19883b;}.cls-1,.cls-2,.cls-3{stroke-width:0px;}.cls-2{fill:#1379b2;}.cls-3{fill:#e8e631;}</style></defs><rect class="cls-1" x="6.92" width="5.79" height="5.79" transform="translate(19.63 5.79) rotate(180)"/><rect class="cls-2" width="5.79" height="5.79" transform="translate(5.79 5.79) rotate(180)"/><rect class="cls-2" x="6.92" y="6.93" width="5.79" height="5.79" transform="translate(19.63 19.65) rotate(180)"/><rect class="cls-3" y="6.93" width="5.79" height="5.79" transform="translate(5.79 19.65) rotate(180)"/></svg>'
                )
                no-repeat left center / 13px auto;
        }
        ._text {
            margin-bottom: 15px;
            font-size: calc(14 / 16 * $font-size-base);
            font-weight: bold;
            color: #fff;
            letter-spacing: 0.08em;
            line-height: calc(24 / 14);
            @include media-down(lg) {
                padding-left: 23px;
            }
        }
        ._btn-wrap {
            display: flex;
            @include media-up(lg) {
                justify-content: flex-end;
            }
            @include media-down(lg) {
                justify-content: center;
            }
        }
        ._text-btn {
            @include c-button-googlelink();
        }
    }
}
