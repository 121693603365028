/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'LINESeedJP';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('../fonts/LineSeed/LINESeedJP_OTF_Rg.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/LineSeed/LINESeedJP_OTF_Rg.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-Bold - latin-ext_latin */
@font-face {
  font-family: 'LINESeedJP';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('../fonts/LineSeed/LINESeedJP_OTF_Bd.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/LineSeed/LINESeedJP_OTF_Bd.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}