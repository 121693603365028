@mixin c-module-title() {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.75em;
    font-weight: normal;
    letter-spacing: 0.22em;
    text-indent: 0.22em;
    font-size: $h2-font-size;
    @include media-down(lg) {
        font-size: 1.875rem;
    }
    @include media-down(md) {
        font-size: $h4-font-size;
    }

    span {
        font-family: 'Roboto', $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        width: 82px;
        height: 1px;
        margin: auto;
        background: $primary;
    }
}

@mixin c-block-title($align: flex-start) {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 30px;
    @if $align == 'center' {
        align-items: center;
        .eng {
          text-align: center;
        }
    }
    @include media-down(md) {
        margin-bottom: 20px;
    }
    .eng {
        font-family: 'LINESeedJP', $font-family-base;
        font-style: normal;
        font-size: calc(43 / 16 * $font-size-base);
        font-weight: 700;
        color: $tertiary;
        letter-spacing: 0.08em;
        line-height: calc(37 / 32);
        @if $align == 'center' {
            text-indent: 0.08em;
        }
        @include media-down(md) {
            font-size: calc(37 / 16 * $font-size-base);
        }
    }
    .ja {
        display: flex;
        align-items: center;
        margin-top: 12px;
        padding-left: 21px;
        font-size: calc(13 / 16 * $font-size-base);
        font-weight: bold;
        font-feature-settings: "palt";
        color: $primary;
        letter-spacing: 0.08em;
        @if $align == 'center' {
            justify-content: center;
            text-indent: 0.08em;
        }
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.71 12.72"><defs><style>.cls-1{fill:#19883b;}.cls-1,.cls-2,.cls-3{stroke-width:0px;}.cls-2{fill:#1379b2;}.cls-3{fill:#e8e631;}</style></defs><rect class="cls-1" x="6.92" width="5.79" height="5.79" transform="translate(19.63 5.79) rotate(180)"/><rect class="cls-2" width="5.79" height="5.79" transform="translate(5.79 5.79) rotate(180)"/><rect class="cls-2" x="6.92" y="6.93" width="5.79" height="5.79" transform="translate(19.63 19.65) rotate(180)"/><rect class="cls-3" y="6.93" width="5.79" height="5.79" transform="translate(5.79 19.65) rotate(180)"/></svg>'
            )
            no-repeat left center / 13px auto;
        @include media-down(md) {
            margin-top: 10px;
        }
    }
}
