$gnav-height-md: 120px;
$gnav-height-xs: 80px;

#allbox {
    // ナビバーが画面固定されたときのクリアランス
    @include media-down(lg) {
        // padding-top: 120px;
    }
    @include media-down(md) {
        // padding-top: 80px;
    }
}

.rwd002-header {
    transition: background-color 0.3s;
    position: fixed;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    padding-left: map-get($spacers, 3);
    padding-right: 0;

    @include media-up(md) {
        padding-left: map-get($spacers, 4);
    }

    @include media-up(lg) {
        padding-left: map-get($spacers, 3); // ie用
        padding-right: map-get($spacers, 3); // ie用
        padding-left: min(map-get($spacers, 3), 5vw);
        padding-right: min(map-get($spacers, 3), 5vw);
    }

    @include media-down(lg) {
        // position: fixed;
        height: 120px;
        top: 0;
        // background-color: $white;
        z-index: 100;
        overflow: hidden;
    }

    @include media-down(md) {
        height: 80px;
    }

    &.is-show {
        background-color: rgba(43, 63, 109, 0.9);
        box-shadow: 0 0 10px rgba(0, 0, 0, .07);
    }

    &__logo {
        flex-grow: 1;
        margin: 0;
        max-height: 100%;
        @include media-up(lg) {
            transform: translateY(-12px);
        }

        img {
            width: auto;
            @media (max-width: 1150px) {
                width: 230px;
                height: auto;
            }
        }

        &.is-img,
        &.is-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-top: map-get($spacers, 2);
            padding-bottom: map-get($spacers, 2);

            @include media-down(lg) {
                justify-content: center;
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        &.is-img {
            @include media-down(lg) {
                height: 100%;
                img {
                    display: block;
                    object-fit: contain;
                    max-height: 100%;
                    transform: translate(-2px, 3px);
                }
            }
        }

        &.is-img:hover,
        &.is-img:focus,
        &.is-text:hover,
        &.is-text:focus {
            @include hoverOpacity;
        }

        &.is-block {
            @include media-down(lg) {
                height: 100%;
            }
        }
    }

    &__logo-link {
        &:hover,
        &:focus {
            text-decoration: none;
        }
        .is-img & {
            display: block;
            @include media-down(lg) {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-top: map-get($spacers, 1);
                padding-bottom: map-get($spacers, 1);
                height: 100%;
            }
        }
        .is-text & {
            display: inline-flex;
            flex-direction: column;
            line-height: 1.8;
            letter-spacing: 0.2em;
            font-feature-settings: 'palt';
            font-size: 2rem;
            small {
                font-size: $font-size-sm;
                line-height: 1.2;
                letter-spacing: 0.05em;
                color: fade-out($body-color, 0.45);
            }
            @include media-down(lg) {
                font-size: $font-size-base;
                line-height: 1.5;
                small {
                    display: none;
                }
            }
        }
    }
}

.rwd002-header__drawerBtn {
    @extend .btn;
    height: 100%;
    position: relative;
    z-index: 101;

    @include media-down(lg) {
        // width: 100px;
        width: 43px;
        height: 43px;
        right: 13px;
        padding: 0;
        background-color: #fff !important;
        // background-color: fade-out($white, 0.5);
    }

    @include media-down(md) {
        // width: 55px;
    }

    &:focus {
        box-shadow: none;
    }

    @include media-up(lg) {
        display: none;
    }

    &__bars {
        stroke: #384e7a;
    }

    &__bar01,
    &__bar02,
    &__bar03 {
        transform-origin: center;
        transition: $transition-base;
    }

    .is-active &__bar01 {
        transform: rotate(135deg) translateY(50%);
    }

    .is-active &__bar02 {
        transform: rotate(45deg);
        opacity: 0;
    }

    .is-active &__bar03 {
        transform: rotate(225deg) translateY(-50%);
    }
}
