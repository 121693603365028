.guideline__title {
    @include c-block-title(center);
    .eng {
        @include media-down(md) {
            font-size: calc(32 / 16 * $font-size-base);
        }
    }
}
.guideline-message {
    padding-inline: map-get($spacers, 3);
    @include media-up(md) {
        margin-top: 60px;
    }
    &__inner {
        width: 100%;
        max-width: 930px;
        margin-inline: auto;
    }
    &__container {
        display: grid;
        grid-template-columns: 400px 460px;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        margin-top: 50px;
        margin-bottom: 45px;
        @media (max-width: 960px) {
            display: block;
            margin-bottom: 115px;
        }
        @include media-down(md) {
            margin-top: 30px;
        }
    }
    &__visual {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        @media (max-width: 960px) {
            flex-direction: inherit;
            justify-content: center;
            align-items: flex-start;
        }
        ._image {
            width: 538px;
            @media (max-width: 1215px) {
                width: calc(((100vw - 930px) / 2) + 400px);
            }
            @media (max-width: 960px) {
                width: 538px;
            }
            @include media-down(sm) {
                width: 320px;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                @media (max-width: 960px) {
                    object-fit: inherit;
                }
            }
        }
    }
    &__body {
        margin-top: 20px;
        // @include media-up(sm) {
        //     width: 452px;
        // }
        @media (max-width: 960px) {
            margin-inline: auto;
            width: 460px;
        }
        @include media-down(sm) {
            width: 320px;
        }
        ._text {
            font-size: calc(16 / 16 * $font-size-base);
            font-weight: 700;
            font-feature-settings: 'palt';
            color: #384e7a;
            letter-spacing: 0.07em;
            @include media-down(sm) {
                font-size: calc(15 / 16 * $font-size-base);
            }
            span {
                display: inline-block;
                width: 100%;
                padding: 10px 0 10px 8px;
                // background: linear-gradient(
                //         to right,
                //         rgba(255, 0, 0, 1) 0%,
                //         rgba(255, 0, 0, 1) 50%,
                //         rgba(255, 0, 0, 0) 51%,
                //         rgba(255, 0, 0, 0) 100%
                //     )
                //     repeat-x left bottom/10px 1px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 449.15 1"><defs><style>.cls-1{stroke-dasharray:0 0 1.99 3.98;}.cls-1,.cls-2{fill:none;stroke:#c5d5ed;stroke-linecap:round;stroke-linejoin:round;}</style></defs><line class="cls-2" x1=".5" y1=".5" x2="1.5" y2=".5"/><line class="cls-1" x1="5.48" y1=".5" x2="445.65" y2=".5"/><line class="cls-2" x1="447.65" y1=".5" x2="448.65" y2=".5"/></svg>'
                    )
                    no-repeat left bottom / auto 1px;
                @include media-down(sm) {
                }
                &.pc-line {
                    @include media-down(sm) {
                        padding: 0;
                    }
                }
                &.sp-line {
                    @include media-up(sm) {
                        display: inline;
                        padding: 0;
                        background: none;
                    }
                }
            }
        }
    }
}
.guideline-aptitude {
    padding: 115px map-get($spacers, 2) 105px;
    background-color: #f3f6fa;
    @include media-down(lg) {
        padding: 130px map-get($spacers, 2) 65px;
    }
    &__fig {
        position: sticky;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 1120px;
        margin-inline: auto;
        @include media-down(lg) {
            justify-content: center;
        }
        img {
            position: absolute;
            top: -170px;
            right: 0;
            @include media-down(lg) {
                top: -195px;
                left: 0;
                right: 0;
                width: 185px;
                height: auto;
                margin: auto;
            }
        }
    }
    &__inner {
        width: 100%;
        max-width: 750px;
        margin-inline: auto;
    }
    &__text {
        margin-bottom: 30px;
        padding-inline: map-get($spacers, 1);
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: 700;
        font-feature-settings: 'palt';
        text-align: center;
        color: #454547;
        letter-spacing: 0.08em;
        line-height: calc(32 / 15);
        @include media-down(sm) {
            text-align: left;
        }
    }
    &__check {
    }
    .check-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        &__item {
            position: relative;
            width: 100%;
            padding: 18px 20px;
            background-color: #fff;
            border-radius: 3px;
            cursor: pointer;
            @include media-down(md) {
                padding: 18px 23px 18px 18px;
            }
            @include media-up(sm) {
                display: flex;
                align-items: center;
            }
            ._head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-shrink: 0;
                @include media-up(sm) {
                    width: 105px;
                    padding-right: 15px;
                    border-right: solid 1px #e5e8ef;
                }
                @include media-down(sm) {
                    width: 80px;
                }
                ._icon {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    width: 47px;
                    height: 35px;
                }
                ._num {
                    font-family: 'LINESeedJP', $font-family-base;
                    font-style: normal;
                    font-size: calc(16 / 16 * $font-size-base);
                    font-weight: 700;
                    color: #55c8d1;
                    letter-spacing: 0.08em;
                    line-height: 1;
                    @include media-down(sm) {
                    }
                }
            }
            ._label {
                margin: 0;
                font-size: calc(15 / 16 * $font-size-base);
                font-weight: 700;
                font-feature-settings: 'palt';
                color: #373737;
                letter-spacing: 0.08em;
                line-height: calc(24 / 15);
                @include media-up(sm) {
                    padding-left: 15px;
                }
                @include media-down(sm) {
                    margin-top: 10px;
                    padding-left: 5px;
                }
            }
            ._checkbox {
                flex-shrink: 0;
                width: 24px;
                margin-left: auto;
                @include media-down(sm) {
                    position: absolute;
                    top: 23px;
                    right: 23px;
                }
            }
            input[type='checkbox'] {
                display: none;
            }
            input[type='checkbox'] + label {
            }
            input[type='checkbox'] + label:before {
                content: ' ';
                position: relative;
                top: 2px;
                display: inline-block;
                width: 24px;
                height: 24px;
                background: #fff;
                vertical-align: bottom;
                border: solid 1px #d6d6d6;
                border-radius: 1px;
            }
            input[type='checkbox']:checked + label:before {
                background: url(/assets/img/guidance/icon_checked.svg) no-repeat
                    center / 17px auto #fff;
            }
        }
    }
    ._result-btnwrap {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    ._result-btn {
        transition: $transition-base;
        width: 260px;
        height: 65px;
        font-size: calc(18 / 16 * $font-size-base);
        font-weight: bold;
        color: #373737;
        letter-spacing: 0.08em;
        background: svgUrlFunc(
                '<svg id="_文字" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.71 35.6"><defs><style>.cls-1{stroke-miterlimit:10;}.cls-1,.cls-2{fill:none;stroke:#211815;stroke-width:1.43px;}.cls-2{stroke-linecap:round;stroke-linejoin:round;}.cls-3{fill:#fff;stroke-width:0px;}</style></defs><path class="cls-3" d="M13.41,15.42c1.52-1.38,2.24-1.15,2.97-1,1.12.23,1.53.82,1.53.82,0,0,.19-.31.81-.64,1.49-.7,2.44.41,3.67,3.32,2.61,6.17,1.47,10.63,1.47,10.63l1.14,2.06-10.52,4.45-.77-1.71S2.31,28.06.97,25.87c-.61-1,.04-2.14,1.04-2.57,1.18-.51,2.55.06,3.7.38.55.15,1.54.43,2.36.43-.83-2.12-5.26-12.19-5.6-14.02-.59-3.14,1.94-3.52,3.26-1.7,1.32,1.82,4.84,9.3,4.84,9.3,0,0-.9-2.2.5-2.46,1.4-.26,2.33.18,2.33.18Z"/><path class="cls-2" d="M13.54,15.35c1.26-1.36,2.1-1.25,2.83-1.1,1.12.23,1.53.82,1.53.82,0,0,.71-.58,1.27-.56,1.55.06,2.07.66,3.2,3.25,2.7,6.13,1.47,10.63,1.47,10.63l1.14,2.06-10.52,4.45-.77-1.71S2.29,27.88.95,25.7c-.61-1,.04-2.14,1.04-2.57,1.18-.51,2.55.06,3.7.38.55.15,1.34.5,2.43.91-.83-2.12-5.32-12.67-5.66-14.49-.59-3.14,1.94-3.52,3.26-1.7,1.32,1.82,4.84,9.3,4.84,9.3"/><path class="cls-2" d="M9.91,16.19c1.1-1.61,2.91-1.23,4.24-.47"/><line class="cls-1" x1="8.11" y1="5.33" x2="9.13" y2=".14"/><line class="cls-1" x1="11.6" y1="6.1" x2="14.41" y2="1.62"/><line class="cls-1" x1="14.02" y1="8.53" x2="18.14" y2="5.22"/></svg>'
            )
            no-repeat right 23px center / 24px auto #ffeea7;
        border: solid 2px #ea7443;
        border-radius: 50px;
        @include media-down(md) {
            width: 230px;
            height: 50px;
            font-size: calc(16 / 16 * $font-size-base);
        }
        @media (hover: hover) {
            &:hover {
                color: #fff;
                background-color: #ea7443;
            }
        }
    }
    .total-score {
        position: relative;
        margin-top: 30px;
        padding-top: 42px;
        border-bottom: solid 2px #2a417d;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.1 21.59"><defs><style>.cls-1{fill:none;stroke:#2a417d;stroke-linecap:round;stroke-linejoin:round;}</style></defs><polyline class="cls-1" points=".5 7.93 22.05 21.09 43.6 7.93"/><polyline class="cls-1" points=".5 7.93 22.05 21.09 43.6 7.93"/><polyline class="cls-1" points=".5 .5 22.05 13.66 43.6 .5"/></svg>'
            )
            no-repeat top center / 43px auto;
        @include media-down(md) {
            padding-top: 30px;
        }
        ._text {
            margin: 0 0 13px 0;
            font-size: calc(17 / 16 * $font-size-base);
            font-weight: 700;
            font-feature-settings: 'palt';
            text-align: center;
            color: #2a417d;
            letter-spacing: 0.08em;
            line-height: 1;
            @include media-down(md) {
                margin-bottom: 10px;
            }
            ._score {
                font-family: 'LINESeedJP', $font-family-base;
                font-style: normal;
                font-size: calc(44 / 16 * $font-size-base);
                font-weight: 700;
                color: #55c8d1;
            }
        }
        ._comment {
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            font-weight: 700;
            font-feature-settings: 'palt';
            text-align: center;
            color: #2a417d;
            letter-spacing: 0.08em;
            line-height: 1;
            @include media-up(md) {
                position: absolute;
                right: 0;
                bottom: 15px;
            }
            @include media-down(md) {
                margin-bottom: 18px;
                text-align: center;
            }
        }
    }
}
.guideline-recruit {
    padding: 100px map-get($spacers, 3) 90px;
    @include media-down(md) {
        padding: 60px map-get($spacers, 3) 80px;
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__list {
        margin-top: 70px;
        @include media-down(md) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
            margin-top: 25px;
        }
        @include media-down(sm) {
            width: 100%;
            max-width: 300px;
            grid-template-columns: 1fr;
            margin-inline: auto;
        }
    }
    &__item {
        overflow: hidden;
        display: flex;
        text-decoration: none !important;
        background-color: rgba(243, 247, 252, 0.8);
        border-radius: 3px;
        @include media-down(md) {
            flex-direction: column;
        }
        &:not(:last-of-type) {
            @include media-up(md) {
                margin-bottom: 20px;
            }
        }
        @media (hover: hover) {
            &:hover &__img {
                img {
                    transform: scale(1.1);
                }
            }
        }
        &__img {
            overflow: hidden;
            aspect-ratio: 383 / 252;
            margin: 0;
            border-radius: 3px;
            @include media-up(md) {
                flex-shrink: 0;
                width: 383px;
            }
            @include media-down(md) {
                width: 100%;
            }
            img {
                transition: $transition-base;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
        &__body {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 50px;
            padding-right: 70px;
            @include media-down(md) {
                padding: 23px 60px 23px 23px;
            }
            .subject {
                margin: 0;
                font-size: calc(21 / 16 * $font-size-base);
                font-weight: bold;
                font-feature-settings: 'palt';
                color: #2a417d;
                letter-spacing: 0.08em;
                line-height: calc(28 / 18);
                @include media-down(md) {
                    font-size: calc(18 / 16 * $font-size-base);
                }
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 20px;
                width: 38px;
                height: 38px;
                margin: auto;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.91 10.12"><defs><style>.cls-1{fill:none;stroke:#54d3d9;stroke-miterlimit:10;stroke-width:1.5px;}</style></defs><path class="cls-1" d="M4.89.55l4.91,4.51-4.91,4.51"/><line class="cls-1" x1="9.8" y1="5.06" x2="0" y2="5.06"/></svg>'
                    )
                    no-repeat center / 13px auto #fff;
                border-radius: 50%;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                @include media-down(sm) {
                    width: 30px;
                    height: 30px;
                    background-size: 10px auto;
                }
            }
        }
    }
}
.guidance-link {
    padding-inline: map-get($spacers, 3);
    &__list {
        @include list-link();
    }
}

.is--guidance {
    .rwd002-detail__head,
    .rwd002-detail__entry {
        max-width: 800px;
    }
    .rwd002-detail__title {
        position: relative;
        margin-bottom: 50px;
        padding-bottom: 18px;
        font-size: calc(30 / 16 * $font-size-base);
        font-weight: bold;
        color: #384e7a;
        letter-spacing: 0.08em;
        line-height: calc(31 / 24);
        border-bottom: none;
        @include media-up(md) {
            text-align: center;
        }
        @include media-down(md) {
            margin-bottom: 25px;
            font-size: calc(24 / 16 * $font-size-base);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100px;
            height: 2px;
            background-color: #55c8d1;
            @include media-up(md) {
                right: 0;
                margin: auto;
            }
        }
    }
    .rwd002-detail__image {
        overflow: hidden;
        border-radius: 3px;
        aspect-ratio: 800 / 496;
        margin: 0;
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .rwd002-detail {
        margin-bottom: 90px;
        @include media-down(md) {
            margin-bottom: 60px;
        }
    }
    .rwd002-detail + .guideline-recruit {
        padding-top: 90px;
        padding-bottom: 0;
        border-top: solid 1px #e5e8ef;
        @include media-down(md) {
            padding-top: 50px;
        }
    }
}

.guidance-detail {
    // padding-inline: map-get($spacers, 3);
    &__inner {
        width: 100%;
        max-width: 800px;
        margin-inline: auto;
    }
    table {
        @include table-typeA();
        tr:first-of-type {
            th,
            td {
                border-top: none;
            }
        }
    }
    h2 {
        margin-top: 75px;
        margin-bottom: 15px;
        padding-top: 30px;
        font-size: calc(20 / 16 * $font-size-base);
        font-weight: bold;
        color: #2a417d;
        letter-spacing: 0.08em;
        line-height: calc(31 / 24);
        border-top: solid 2px #2a417d;
        @include media-down(md) {
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
    ol,
    ul {
        counter-reset: number 0;
        margin: 10px 0 0 0;
        padding: 0;
        list-style-type: none;
        li {
            position: relative;
            padding: 20px 0;
            &:not(:last-of-type) {
                border-bottom: solid 1px #d8dce6;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 18px;
                    height: 6px;
                    background: svgUrlFunc(
                            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.28 6.51"><defs><style>.cls-1{fill:#fff;stroke:#d8dce6;stroke-miterlimit:10;stroke-width:1.03px;}</style></defs><path class="cls-1" d="M18.01.44l-8.87,5.46L.27.44"/></svg>'
                        )
                        no-repeat bottom center / 18px auto;
                }
            }
            &::before {
                counter-increment: number 1;
                content: '0' counter(number);
                position: absolute;
                top: 22px;
                left: 0;
                font-family: 'LINESeedJP', $font-family-base;
                font-style: normal;
                font-size: calc(18 / 16 * $font-size-base);
                font-weight: 700;
                color: #55c8d1;
                line-height: 1;
                letter-spacing: 0.08em;
            }
        }
        h3,
        p {
            padding-left: 45px;
            font-feature-settings: 'palt';
            letter-spacing: 0.08em;
        }
        h3 {
            margin-bottom: 6px;
            font-size: calc(19 / 16 * $font-size-base);
            font-weight: bold;
            color: #384e7a;
        }
        p {
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            color: #373737;
            line-height: calc(23 / 15);
        }
    }
    &__title {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px;
        @include media-down(md) {
            margin-bottom: 30px;
        }
        .ja {
            display: flex;
            align-items: center;
            margin-top: 12px;
            padding-left: 21px;
            font-size: calc(35 / 16 * $font-size-base);
            font-weight: bold;
            font-feature-settings: 'palt';
            color: $primary;
            letter-spacing: 0.08em;
            @include media-down(md) {
                margin-top: 10px;
                font-size: calc(24 / 16 * $font-size-base);
            }
        }
        .eng {
            margin-top: 2px;
            font-family: 'LINESeedJP', $font-family-base;
            font-style: normal;
            font-size: calc(13 / 16 * $font-size-base);
            font-weight: 700;
            color: $tertiary;
            letter-spacing: 0.08em;
            line-height: calc(37 / 32);
            @include media-down(md) {
            }
        }
    }
}
.guidance-detail__btn-wrap {
    display: flex;
    justify-content: center;
    margin-top: 45px;
    @include media-down(md) {
        margin-top: 30px;
    }
}
.guidance-detail__btn {
    transition: $transition-base;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 473px;
    height: 100px;
    padding-right: 40px;
    font-family: 'LINESeedJP', $font-family-base;
    font-style: normal;
    font-size: calc(22 / 16 * $font-size-base);
    color: #fff !important;
    text-decoration: none !important;
    letter-spacing: 0.08em;
    background-color: #ff6432;
    border-radius: 3px;
    @include media-down(md) {
        padding-right: 30px;
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            background-color: #e83b2e;
        }
    }
    ._icon {
        display: flex;
        align-items: center;
        height: auto;
        margin-right: 45px;
        @include media-down(md) {
            margin-right: 22px;
        }
    }
}
.guidance-detail__back-btn {
    @include c-button-arrow();
}
