.is--about {
    .rwd002-container,
    .footer-wrap {
        background-color: #f3f6fa;
    }
}
.about-wrap {
    overflow: hidden;
    padding: 70px map-get($spacers, 3) 125px;
    @include media-down(md) {
        padding: 100px map-get($spacers, 3) 50px;
    }
    background-color: #fff;
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
}
.about-story {
    display: flex;
    margin-bottom: 70px;
    @include media-up(lg) {
        justify-content: space-between;
        flex-direction: row-reverse;
        gap: 30px;
    }
    @include media-down(lg) {
        flex-direction: column;
        gap: 20px;
    }
    @include media-down(md) {
        margin-bottom: 40px;
    }
    &__body {
        width: 100%;
        @include media-up(lg) {
            flex-shrink: 0;
            max-width: 385px;
            margin-top: 40px;
        }
    }
    &__title {
        @include c-block-title();
        @include media-down(md) {
            .eng {
                font-size: calc(32 / 16 * $font-size-base);
            }
        }
    }
    &__text {
        margin: 0;
        font-size: calc(15 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        color: #454547;
        letter-spacing: 0.08em;
        line-height: calc(31 / 15);
        @include media-down(md) {
            line-height: calc(26 / 15);
        }
    }
    &__image {
        @include media-down(lg) {
            margin: auto;
        }
        @include media-down(md) {
            width: 229px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.about-introduction {
    display: flex;
    @include media-up(lg) {
        justify-content: space-between;
        gap: 10px;
    }
    @include media-down(lg) {
        flex-direction: column;
        gap: 20px;
    }
    &__body {
        width: 100%;
        @include media-up(lg) {
            flex-shrink: 0;
            max-width: 420px;
            margin-top: 20px;
        }
    }
    &__title {
        @include c-block-title();
        @include media-down(md) {
            .eng {
                font-size: calc(32 / 16 * $font-size-base);
            }
        }
    }
    &__text {
        width: 100%;
        margin: 0;
        font-size: calc(15 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        color: #454547;
        letter-spacing: 0.08em;
        line-height: calc(31 / 15);
        @include media-up(lg) {
            max-width: 380px;
        }
        @include media-down(md) {
            line-height: calc(26 / 15);
        }
    }
    &__image {
        @include media-down(lg) {
            margin: auto;
        }
        @include media-down(md) {
            width: 264px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.about-staff {
    padding: 0 map-get($spacers, 2) 120px;
    background-color: #fff;
    @include media-down(md) {
        padding-bottom: 65px;
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__container {
        display: grid;
        @include media-up(md) {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
        @include media-down(md) {
            grid-template-columns: 1fr;
            gap: 25px;
        }
    }
    &__item {
        padding: 40px map-get($spacers, 2) 33px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        @include media-down(md) {
            padding: 38px map-get($spacers, 2) 35px;
            border-radius: 4px;
        }
        ._icon {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 50px;
            margin-bottom: 10px;
        }
    }
    &__item-body {
        width: 300px;
        margin-inline: auto;
        @include media-down(md) {
            width: 267px;
        }
    }
    &__title {
        @include c-block-title(center);
        margin-bottom: 20px !important;
        .eng {
            font-size: calc(32 / 16 * $font-size-base);
        }
        .ja {
            margin-top: 8px;
            padding: 0;
            background: none;
        }
    }
    p {
        margin: 0;
        font-size: calc(15 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        color: #454547;
        letter-spacing: 0.08em;
        line-height: calc(33 / 15);
    }
    ._text-right {
        text-align: right;
    }
    &__lead {
        width: 100%;
        margin: 0 0 10px !important;
        text-align: center;
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding-left: 20px;
            font-size: calc(15 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            color: #454547;
            letter-spacing: 0.08em;
            line-height: calc(33 / 15);
            &::before {
                content: '';
                position: absolute;
                top: 0.7rem;
                left: 0;
                width: 9px;
                height: 9px;
                background-color: #55c8d1;
                border-radius: 50%;
            }
        }
    }
    &__text {
        padding-left: 20px;
    }
}
.about-overview {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -15px;
    background: url(/assets/img/webp/about/pho-overview@2x.webp) no-repeat
        center / cover #fff;
    border-radius: 15px;
    @include media-up(sm) {
        height: 640px;
    }
    @include media-down(sm) {
        height: 585px;
        background-image: url(/assets/img/webp/about/pho-overview_sp@2x.webp);
    }
    &__title {
        @include c-block-title(center);
        .ja {
            color: #fff;
        }
        @include media-down(md) {
            margin-bottom: 35px;
            .eng {
                font-size: calc(32 / 16 * $font-size-base);
            }
        }
    }
    &__text {
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        font-feature-settings: 'palt';
        text-align: center;
        color: #fff;
        letter-spacing: 0.08em;
        line-height: calc(38 / 15);
        @include media-down(md) {
            line-height: calc(31 / 15);
        }
    }
}
.about-faq {
    padding: 100px map-get($spacers, 3) 90px;
    @include media-down(md) {
        padding: 60px map-get($spacers, 3);
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__title {
        @include c-block-title(center);
        margin-bottom: 40px;
        @include media-down(md) {
            margin-bottom: 30px;
            .eng {
                font-size: calc(32 / 16 * $font-size-base);
            }
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    &__item {
        padding: 37px 80px;
        background-color: #fff;
        border-radius: 10px;
        @include media-down(md) {
            padding: 23px 22px;
        }
        ._question {
            position: relative;
            display: flex;
            margin-bottom: 8px;
            padding-left: 45px;
            font-size: calc(20 / 16 * $font-size-base);
            font-weight: bold;
            font-feature-settings: 'palt';
            color: #384e7a;
            letter-spacing: 0.08em;
            line-height: calc(35 / 20);
            @include media-down(md) {
                margin-bottom: 13px;
                padding-top: 5px;
                padding-left: 40px;
                font-size: calc(18 / 16 * $font-size-base);
                line-height: calc(24 / 18);
            }
            &::before {
                content: 'Q';
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 33px;
                height: 33px;
                font-family: 'LINESeedJP', $font-family-base;
                font-style: normal;
                font-size: calc(18 / 16 * $font-size-base);
                font-weight: 700;
                color: #fff;
                background-color: #f9b946;
                border-radius: 50%;
                @include media-down(md) {
                    width: 30px;
                    height: 30px;
                    font-size: calc(15 / 16 * $font-size-base);
                }
            }
        }
        ._anwser {
            margin: 0;
            padding-left: 45px;
            font-size: calc(15 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.08em;
            line-height: calc(28 / 15);
            @include media-down(md) {
                padding-left: 0;
                line-height: calc(26 / 15);
                br {
                    display: none;
                }
            }
        }
    }
}
.about-link {
    padding-inline: map-get($spacers, 3);
    &__list {
        @include list-link();
    }
}
