.rwd002-breadcrumbs {
  position: relative;
  top: -140px;
  display: flex;
  width: 100%;
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  // margin-top: map-get($spacers, 6);
  // margin-bottom: map-get($spacers, 6);

  @include media-up(lg) {
    // padding-left: map-get($spacers, 5);
    // padding-right: map-get($spacers, 5);
  }
  @include media-down(lg) {
    display: none;
  }
  &__list {
    @extend .breadcrumb;
    margin: 0;
    margin-left: 120px;
    // width: 100%;
    // max-width: 1100px;
  }
  &__item {
    @extend .breadcrumb-item;
    @extend .text-truncate;
    padding-left: 0;
    margin-right: 5px;
    // max-width: 33%;
    font-size: calc(10 / 16 * $font-size-base);
    font-weight: bold;
    text-transform: uppercase;
    color: #384e7a;
    letter-spacing: 0.08em;
    font-feature-settings: "palt";
    @include media-down(md) {
      // font-size: $font-size-xs;
      // max-width: 14em;
    }
    & + &{
      padding-left: 0;
    }
    & + &::before {
      content: "-";
      padding-right: 5px;
      color: #384e7a;
    }
    // &:last-child {
    //   color: $primary;
    // }
  }
}
