.rwd002-footerSocial {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 90px;
    @include media-up(lg) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    &__title {
        letter-spacing: 0.16em;
        margin-right: 1em;
        font-family: 'Lato', $font-family-sans-serif;
    }
    &__btn {
        @extend .btn;
        font-size: 1.8em;
        margin: 0 0.1em;
        line-height: 1;
        transition: $transition-base;
    }
    &__btn.is-fb {
        &:hover,
        &:focus {
            color: $sns-facebook-bg;
        }
    }
    &__btn.is-tw {
        &:hover,
        &:focus {
            color: $sns-twitter-bg;
        }
    }
    &__btn.is-line {
        &:hover,
        &:focus {
            color: $sns-line-bg;
        }
    }
}

.footer-wrap {
    @include media-up(lg) {
        display: grid;
        grid-template-columns: 1fr 60px;
    }
}
.footer-container {
    @include media-up(lg) {
        order: 1;
    }
}
.rwd002-pagetop {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(14 / 16 * $font-size-base);
    font-family: 'LINESeedJP', $font-family-base;
    font-style: normal;
    font-weight: 700;
    color: #384e7a !important;
    text-decoration: none !important;
    letter-spacing: 0.08em;
    transform-origin: bottom;
    @include media-up(lg) {
        width: 60px;
    }
    span {
        display: block;
        @include media-up(lg) {
            transform: rotate(90deg);
            width: 90px;
        }
    }
    &:before {
        transition: $transition-base;
        content: '';
        display: block;
        width: 37px;
        height: 37px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.09 13.98"><defs><style>.cls-1{fill:none;stroke:#54d3d9;stroke-miterlimit:10;stroke-width:1.5px;}</style></defs><path class="cls-1" d="M.48,3.9L4.05.97l3.57,2.93"/><line class="cls-1" x1="4.05" y1="13.98" x2="4.05" y2="1.41"/></svg>'
            )
            no-repeat center / 7px auto #fff;
        border-radius: 50%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        @include media-up(lg) {
            margin-bottom: 55px;
        }
        @include media-down(lg) {
            margin-bottom: 10px;
        }
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            text-decoration: none;
            &:before {
                transform: translateY(-10px);
            }
        }
    }
    &__wrap {
        width: 100%;
        z-index: 10;
        transition: $transition-base;
        @include media-up(lg) {
            order: 2;
            margin: 0 auto;
        }
        @include media-down(lg) {
            position: relative;
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
        }
    }
}

.footer-contact-nav {
    @include media-up(md) {
        display: flex;
    }
    &__link {
        transition: $transition-base;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;
        color: #fff !important;
        @include media-up(lg) {
            height: 170px;
        }
        @include media-down(lg) {
            height: 90px;
        }
        @include media-up(md) {
            width: 50%;
        }
        @include media-down(md) {
            width: 100%;
        }
        &._guidance {
            font-family: 'Noto Sans JP', $font-family-base;
            font-size: calc(21 / 16 * $font-size-base);
            font-weight: 500;
            letter-spacing: 0.2em;
            background-color: #2abac5;
            @include media-down(md) {
                font-size: calc(20 / 16 * $font-size-base);
                ._icon {
                    width: 27px;
                    margin-right: 17px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            @media (hover: hover) {
                &:hover,
                &:focus {
                    background-color: #2a90c5;
                }
            }
        }
        &._entry {
            font-family: 'LINESeedJP', $font-family-base;
            font-style: normal;
            font-size: calc(25 / 16 * $font-size-base);
            letter-spacing: 0.24em;
            background-color: #ff6432;
            @include media-down(md) {
                font-size: calc(20 / 16 * $font-size-base);
                ._icon {
                    width: 20px;
                    margin-right: 20px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            @media (hover: hover) {
                &:hover,
                &:focus {
                    background-color: #e83b2e;
                }
            }
        }
        ._icon {
            display: flex;
            align-items: center;
            height: auto;
            margin-right: 25px;
        }
    }
}

.rwd002-footer {
    position: relative;
    background-color: #2b3f6d;
    color: $white;
    @include media-up(lg) {
        padding: 90px map-get($spacers, 5) map-get($spacers, 3);
    }
    @include media-down(lg) {
        padding: 35px map-get($spacers, 3) map-get($spacers, 3);
    }

    &__inner {
        width: 100%;
        max-width: 820px;
        margin: 0 auto;
        @include media-up(lg) {
            display: grid;
            justify-content: space-between;
            align-items: flex-end;
            grid-template-columns: 360px 370px;
            margin-bottom: 100px;
        }
        @include media-down(lg) {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }
    }

    &__info {
        @include media-up(lg) {
            flex-grow: 1;
            // padding-right: map-get($spacers, 3);
        }
        @include media-down(lg) {
            margin-bottom: 20px;
            padding-bottom: 25px;
            border-bottom: solid 1px rgba(255, 255, 255, 0.3);
        }
    }
    &__companyLogo {
        margin-bottom: 25px;
        @include media-down(lg) {
            text-align: center;
        }
    }
    &__companyBody {
        @include media-up(lg) {
            padding-left: 20px;
        }
        @include media-down(lg) {
            text-align: center;
        }
    }
    &__companyName {
        margin: 0;
        font-size: calc(13 / 16 * $font-size-base);
        letter-spacing: 0.08em;
        line-height: calc(24 / 13);
        font-feature-settings: 'palt';
    }
    &__address {
        font-size: calc(13 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        color: #fff;
        letter-spacing: 0.08em;
        line-height: calc(24 / 13);
        &:last-child {
            margin-bottom: 0;
        }
        .zip,
        .addr2 {
            // display: none;
        }
        .zip {
            @include media-down(sm) {
                display: block;
            }
        }
        .opentime {
            display: inline-block;
            margin-bottom: 13px;
            @include media-down(lg) {
                display: block;
            }
        }
        .tel,
        .fax {
            font-size: calc(15 / 16 * $font-size-base);
            font-family: 'LINESeedJP', $font-family-base;
            font-style: normal;
            color: #fff;
            letter-spacing: 0.08em;
            line-height: calc(24 / 13);
            @include media-down(sm) {
                display: block;
            }
        }
        .fax {
            @include media-up(md) {
                margin-left: 7px;
            }
        }
    }

    &__copyright,
    &__login {
        display: block;
        text-align: center;
        font-size: $font-size-xxs;
        letter-spacing: 0.14em;
        text-indent: 0.14em;
        font-feature-settings: 'palt';
    }

    &__copyright {
        color: rgba(255, 255, 255, 0.6);
        @include media-down(md) {
            margin-left: -1.3em;
            margin-right: -1.3em;
        }
    }

    &__login {
        position: absolute;
        z-index: 99;
        @include media-up(lg) {
            bottom: 17px;
            right: -53px;
        }
        @include media-down(lg) {
            bottom: 17px;
            right: 10px;
        }
    }
}
.rwd002-footer__content {
    @include media-down(lg) {
        position: relative;
        // width: fit-content;
        margin: 0 auto 50px;
    }
}
.rwd002-footerNav {
    @include media-up(lg) {
        // display: grid;
        // grid-template: 1fr auto / 2fr 1fr;
        // flex-basis: 60%;
    }
    @include media-down(lg) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 300px;
    }
    @include media-down(md) {
        display: block;
    }
    &__list,
    &__sublist {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        color: #fff;
        @include media-down(lg) {
            justify-content: center;
            width: 100%;
        }
        @include media-down(md) {
        }
    }
    &__sublist {
        display: flex;
        @include media-up(lg) {
            margin-top: 35px;
        }
        @include media-down(lg) {
            justify-content: center;
            // position: absolute;
            // bottom: 4px;
            // right: 22px;
        }
    }
}

.rwd002-footerNav-item {
    display: flex;
    flex-wrap: wrap;
    @include media-up(md) {
        // padding-right: 1em;
    }
    @include media-down(md) {
        // position: relative;
        // border: solid darken($white, 50%);
        // border-width: 1px 0;
        // & + & {
        //     border-top-width: 0;
        // }
    }
    &:last-of-type {
        @include media-down(lg) {
            transform: translateX(-82px);
        }
    }
    &:not(:last-of-type) &__link {
        &:after {
            content: '/';
            display: inline-block;
            margin-inline: 10px;
        }
    }
    &:nth-of-type(4) &__link {
        @include media-up(lg) {
            &:after {
                @include media-up(md) {
                    display: none;
                }
            }
        }
    }
    &:nth-of-type(3) &__link,
    &:nth-of-type(7) &__link {
        @include media-down(lg) {
            &:after {
                display: none;
            }
        }
    }
    @include media-down(lg) {
        &.is-contact {
            display: none;
        }
    }
    &__link {
        position: relative;
        display: inline-block;
        padding: 0.3em 0;
        font-size: calc(13 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        color: #fff;
        letter-spacing: 0.08em;
        &:hover,
        &:focus {
            text-decoration: underline;
        }

        @include media-down(md) {
            // flex-grow: 1;
            // flex-basis: calc(100% - 40px);
            // padding: 0.55em 40px 0.55em 1.7em;
            // &:after {
            //     top: 1.25em;
            //     left: 0.7em;
            // }
        }
    }
}

.rwd002-footerNav-subitem {
    position: 4px;
    &:not(:last-of-type) &__link {
        &:after {
            content: '/';
            display: inline-block;
            margin-inline: 4px;
            color: rgba(255, 255, 255, 0.6) !important;
        }
    }
    &__link {
        display: inline-block;
        transition: $transition-base;
        font-size: calc(12 / 16 * $font-size-base);
        font-family: 'Outfit', sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        color: rgba(255, 255, 255, 0.6);
        letter-spacing: 0.08em;
        &:hover,
        &:focus {
            color: $white;
        }
        @include media-down(lg) {
        }
    }
}

.rwd002-footer-subnav {
    @include media-up(md) {
        display: none;
    }
    &__tgl {
        @extend .btn;

        &:focus {
            box-shadow: none;
        }

        @include media-up(md) {
            display: none;
        }
        @include media-down(md) {
            position: relative;
            flex-basis: 40px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: calc(50% - 5px);
                width: 10px;
                height: 1px;
                background-color: darken($white, 50%);
                transition: transform 0.2s ease-in-out;
            }

            &:after {
                transform: rotate(-90deg);
            }

            &.is-active {
                &:before {
                    transform: rotate(180deg);
                }
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }
    &__list {
        list-style: none;
        @include media-down(md) {
            padding-left: 1.7em;
            padding-bottom: 1em;
        }
    }
}

.rwd002-footer-subnav-item {
    &__link {
        @include media-down(md) {
            display: block;
            padding: 0.2em 0;
        }
    }
}

.rwd002-footer-search {
    margin-top: 2em;
    @include media-down(lg) {
        width: 100%;
        margin-top: 1.5em;
        margin-bottom: 3em;
    }
    &__inner {
        position: relative;
        max-width: 320px;
        @include media-down(md) {
            margin-left: -0.8em;
            margin-right: -0.8em;
        }
    }
    &__input {
        @extend .form-control;
        padding-right: 2.2em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: tint($dark, 10%);
        transition: $transition-base;
        border-width: 0;
    }
    &__btn {
        @extend .btn;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100% - 2.2em);
        width: 2.2em;
        padding: 0 0.5em;
        pointer-events: none;
        transition: $transition-base;
        > svg {
            width: 1em;
            height: 100%;
            fill: tint($dark, 30%);
        }
    }
    &__btn:focus,
    &__input:focus + &__btn {
        pointer-events: auto;
        right: 0;
    }
}
