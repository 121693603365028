.rwd002-container {
  padding-bottom: 120px;
  // margin-top: map-get($spacers, 6);
  @include media-down(lg) {
    padding-bottom: 40px;
    // margin-top: map-get($spacers, 3);
  }

  .is--news & {
    padding: 0;
    margin-bottom: 120px;
    @include media-down(lg) {
      margin-bottom: 40px;
    }
  }
}