@function svg-right-angle($theme: $primary) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
    );
}

@function svg-bottom-angle($theme: $body-color) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>'
    );
}

.btn-icon-right-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 1em;
        width: 1em;
        height: 0.6em;
        background: center / contain no-repeat;
        background-image: svg-right-angle();
    }
    &:hover:after,
    &:focus:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-right-angle-white {
    @extend .btn-icon-right-angle;
    &:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-bottom-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 0;
        width: 2em;
        height: 0.6em;
        transition: all 0.5s ease-in-out;
        background: center / contain no-repeat;
        background-image: svg-bottom-angle();
        transform: rotateX(180deg);
    }
    &.collapsed:after {
        transform: rotateX(0deg);
    }
}

@mixin c-button-view($align: flex-start) {
    transition: $transition-base;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 213px;
    height: 53px;
    padding-left: 33px;
    font-family: 'LINESeedJP', $font-family-base;
    font-style: normal;
    font-size: calc(13 / 16 * $font-size-base);
    font-weight: 700;
    text-decoration: none !important;
    color: #199f5d;
    letter-spacing: 0.08em;
    line-height: 1;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    .icon {
        transition: $transition-base;
        position: relative;
        width: 22px;
        height: 22px;
        margin-right: 16px;
        background-color: #ffeea7;
        border-radius: 50%;
        transform-origin: center;
        // @include media-down(sm) {
        //     top: inherit;
        //     bottom: 5px;
        // }
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 7px;
            height: 7px;
            background-color: #ffca5c;
            border-radius: 50%;
        }
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            color: #fff;
            background-color: #ffb315;
            .icon {
                width: 26px;
                height: 26px;
            }
        }
    }
}
@mixin c-button-googlelink($align: flex-start) {
    display: flex;
    align-items: center;
    width: 146px;
    height: 40px;
    padding-left: 18px;
    font-family: 'LINESeedJP', $font-family-base;
    font-style: normal;
    font-size: calc(10 / 16 * $font-size-base);
    font-weight: 700;
    color: #2b3f6d;
    text-decoration: none !important;
    letter-spacing: 0.08em;
    line-height: 1;
    border-radius: 50px;
    background-color: #fff;
    background: svgUrlFunc(
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.91 10.12"><defs><style>.cls-1{fill:none;stroke:#54d3d9;stroke-miterlimit:10;stroke-width:1.5px;}</style></defs><path class="cls-1" d="M4.89.55l4.91,4.51-4.91,4.51"/><line class="cls-1" x1="9.8" y1="5.06" x2="0" y2="5.06"/></svg>'
        )
        no-repeat right 13px center / 10px auto #fff;
    span {
        display: flex;
        align-items: center;
        height: 15px;
        padding-left: 15px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.78 11.45"><defs><style>.cls-1{fill:#2b3f6d;stroke-width:0px;}</style></defs><path class="cls-1" d="M3.89,11.45c.19,0,3.89-5.41,3.89-7.56S6.04,0,3.89,0,0,1.74,0,3.89s3.7,7.56,3.89,7.56ZM2.4,3.89c0-.82.67-1.49,1.49-1.49s1.49.67,1.49,1.49-.67,1.49-1.49,1.49-1.49-.67-1.49-1.49Z"/></svg>'
            )
            no-repeat left center / 8px auto;
    }
}
@mixin c-button-arrow($align: flex-start) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 214px;
    height: 54px;
    font-family: 'LINESeedJP', $font-family-base;
    font-style: normal;
    font-size: calc(14 / 16 * $font-size-base);
    font-weight: 700;
    color: #2b3f6d;
    text-decoration: none !important;
    letter-spacing: 0.08em;
    line-height: 1;
    border-radius: 50px;
    background-color: #fff;
    border: solid 1px #2b3f6d;
    @media (hover: hover) {
        &:hover {
            &::before {
                left: 18px;
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 23px;
        transition: $transition-base;
        display: flex;
        align-items: center;
        width: 12px;
        height: 11px;
        margin: auto;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.24 12.3"><defs><style>.cls-1{stroke:#fff;stroke-width:2px;}.cls-1,.cls-2{fill:none;stroke-miterlimit:10;}.cls-2{stroke:#384e7a;stroke-width:1.5px;}</style></defs><path class="cls-1" d="M7.38,11.57L1.48,6.15,7.38.74"/><line class="cls-1" x1="1.48" y1="6.15" x2="13.24" y2="6.15"/><path class="cls-2" d="M7.38,11.57L1.48,6.15,7.38.74"/><line class="cls-2" x1="1.48" y1="6.15" x2="13.24" y2="6.15"/><path class="cls-2" d="M7.38,11.57L1.48,6.15,7.38.74"/><line class="cls-2" x1="1.48" y1="6.15" x2="13.24" y2="6.15"/><path class="cls-2" d="M7.38,11.57L1.48,6.15,7.38.74"/><line class="cls-2" x1="1.48" y1="6.15" x2="13.24" y2="6.15"/></svg>'
            )
            no-repeat left center / 12px auto;
    }
}
