.rwd002-moduletitle {
    margin-top: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 4);
    text-align: center;
    @include media-down(md) {
        margin-top: map-get($spacers, 4);
        margin-bottom: map-get($spacers, 4);
    }
    &__inner {
        @include c-module-title;
        padding-left: 1.6875rem;
        padding-right: 1.6875rem;
    }
}
.moduletitle {
    position: relative;
    height: 485px;
    display: flex;
    padding-left: 115px;
    background: url(/assets/img/webp/back-head@2x.webp) repeat-x top center /
        auto 100%;
    @include media-down(xl) {
    }
    @include media-down(lg) {
        padding-left: 50px;
    }
    @include media-down(md) {
        padding-left: 23px;
    }
    @include media-up(sm) {
        align-items: center;
    }
    @include media-down(sm) {
        padding-top: 130px;
        background-image: url(/assets/img/webp/back-head_sp@2x.webp);
    }
    // @include media-down(md) {
    //     .is--news &,.is--privacy &,.is--sitemap & {
    //         height: 300px;
    //     }
    // }
    .is--news &,
    .is--privacy &,
    .is--sitemap &,
    .is--contact & {
        @include media-down(md) {
            height: 250px;
            padding-top: 50px;
        }
        @include media-down(sm) {
            height: 230px;
            padding-top: 103px;
        }
    }
    &__title {
        display: flex;
        flex-direction: column;
        margin: -15px 0 0;
        color: #fff;
        @include media-down(sm) {
            margin: 0;
        }
        .eng {
            padding-left: 30px;
            font-family: 'LINESeedJP', $font-family-base;
            font-style: normal;
            font-size: calc(60 / 16 * $font-size-base);
            font-weight: 700;
            letter-spacing: 0.08em;
            line-height: 1;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.71 12.72"><defs><style>.cls-1{fill:#19883b;}.cls-1,.cls-2,.cls-3{stroke-width:0px;}.cls-2{fill:#1379b2;}.cls-3{fill:#e8e631;}</style></defs><rect class="cls-1" x="6.92" width="5.79" height="5.79" transform="translate(19.63 5.79) rotate(180)"/><rect class="cls-2" width="5.79" height="5.79" transform="translate(5.79 5.79) rotate(180)"/><rect class="cls-2" x="6.92" y="6.93" width="5.79" height="5.79" transform="translate(19.63 19.65) rotate(180)"/><rect class="cls-3" y="6.93" width="5.79" height="5.79" transform="translate(5.79 19.65) rotate(180)"/></svg>'
                )
                no-repeat left center / 13px auto;
            @include media-down(md) {
                font-size: calc(37 / 16 * $font-size-base);
            }
        }
        .ja {
            margin-top: 8px;
            padding-left: 30px;
            font-size: calc(12 / 16 * $font-size-base);
            font-weight: bold;
            letter-spacing: 0.08em;
            line-height: 1;
        }
    }
    &__image {
        position: absolute;
        @media (max-width: 1050px) {
            transform: scale(0.7);
            transform-origin: right center;
        }
        @include media-down(lg) {
            right: map-get($spacers, 3) !important;
        }
        @include media-down(md) {
            transform: inherit;
            top: 0;
            bottom: 0;
            width: 100%;
            height: auto;
            margin: auto;
        }
        @include media-down(sm) {
            top: inherit;
            left: 0;
            right: 0 !important;
        }
        .is--about.is--article-1 & {
            bottom: 4px;
            right: 250px;
            @include media-down(xl) {
                right: 190px;
            }
            @include media-down(md) {
                max-width: 330px;
            }
            @include media-down(sm) {
                bottom: -35px;
            }
        }
        .is--detail.is--article-8 & {
            bottom: 7px;
            right: 330px;
            @include media-down(xl) {
                right: 230px;
            }
            @include media-down(md) {
                max-width: 248px;
            }
            @include media-down(sm) {
                bottom: 16px;
            }
        }
        .is--company.is--article-1 & {
            bottom: 6px;
            right: 237px;
            @include media-down(xl) {
                right: 137px;
            }
            @include media-down(md) {
                max-width: 277px;
            }
            @include media-down(sm) {
                bottom: 40px;
            }
        }
        .is--guidance.is--detail & {
            bottom: 37px;
            right: 282px;
            @include media-down(xl) {
                right: 182px;
            }
            @include media-down(md) {
                max-width: 160px;
            }
            @include media-down(sm) {
                bottom: 35px;
            }
        }
    }
}
