.detail-job {
    position: relative;
    margin-top: 90px;
    margin-bottom: 55px;
    padding-inline: map-get($spacers, 2);
    @include media-down(md) {
        margin-top: 0;
        margin-bottom: 40px;
    }
    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
    }
    &__container {
        border: solid 3px #384e7a;
        border-radius: 15px;
        @include media-down(md) {
            border-radius: 10px;
        }
    }
    &__title {
        position: relative;
        margin: 0;
        padding: 15px;
        font-size: calc(30 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #fff;
        letter-spacing: 0.08em;
        background-color: #384e7a;
        border-radius: 10px 10px 0 0;
        @include media-down(md) {
            font-size: calc(23 / 16 * $font-size-base);
            border-radius: 5px 5px 0 0;
        }
        &::before {
            content: '';
            position: absolute;
            top: calc(100% - 1px);
            left: 0;
            right: 0;
            width: 24px;
            height: 12px;
            clip-path: polygon(0 0, 100% 0, 50% 100%);
            margin: auto;
            background-color: #384e7a;
            @include media-down(md) {
                height: 8px;
            }
        }
    }
    &__image {
        margin-bottom: -47px;
        padding: 60px map-get($spacers, 2) 0;
        @include media-down(md) {
            margin-bottom: -40px;
            padding-top: 30px;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: auto;
            @include media-down(sm) {
                width: 348px;
            }
        }
    }
}
.detail-facility {
    margin-bottom: 90px;
    padding-inline: map-get($spacers, 2);
    @include media-down(md) {
        margin-bottom: 65px;
    }
    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
    }
    &__container {
        overflow: hidden;
        border: solid 3px #55c8d1;
        border-radius: 15px;
        @include media-down(md) {
            border-radius: 10px;
        }
    }
    &__title {
        position: relative;
        margin: 0;
        padding: 15px;
        font-size: calc(30 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #fff;
        letter-spacing: 0.08em;
        background-color: #55c8d1;
        @include media-down(md) {
            font-size: calc(23 / 16 * $font-size-base);
        }
        &::before {
            content: '';
            position: absolute;
            top: calc(100% - 1px);
            left: 0;
            right: 0;
            width: 24px;
            height: 12px;
            clip-path: polygon(0 0, 100% 0, 50% 100%);
            margin: auto;
            background-color: #55c8d1;
            @include media-down(md) {
                height: 8px;
            }
        }
    }
    &__image {
        padding: 70px map-get($spacers, 2) 50px;
        @include media-down(md) {
            padding-block: 30px;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: auto;
            @include media-down(sm) {
                width: 310px;
            }
        }
    }
}
.detail-link {
    padding-inline: map-get($spacers, 3);
    &__list {
        @include list-link();
    }
}
