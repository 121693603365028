@mixin table-typeA {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    tr:first-of-type {
        th,
        td {
            @include media-down(md) {
                border-top: none;
                &::before {
                    display: none;
                }
            }
        }
    }
    th,
    td {
        font-size: calc(15 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        vertical-align: top;
        color: #2a2c2c;
        letter-spacing: 0.08em;
        line-height: calc(29 / 15);
        @include media-down(md) {
            display: block;
            line-height: calc(24 / 14);
        }
    }
    th {
        padding: 18px 0 18px 10px;
        font-weight: bold;
        white-space: nowrap;
        color: #2b3f6d;
        border-top: solid 1px #55c8d1;
        @include media-up(md) {
            width: 112px;
        }
        @include media-down(md) {
            position: relative;
            width: 100%;
            padding: 16px 6px 0 6px;
            border-top: solid 1px #d8dce6;
            // border-width: 0;
            // padding-bottom: 0;
            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                width: 72px;
                height: 1px;
                background-color: #55c8d1;
            }
        }
    }
    td {
        padding: 18px 0 18px 45px;
        color: #373737;
        border-top: solid 1px #d8dce6;
        @include media-up(md) {
            font-size: calc(15 / 16 * $font-size-base);
        }
        @include media-down(md) {
            width: 100%;
            padding: 1px 6px 16px 6px;
            letter-spacing: 0.13em;
            border-top: none;
        }
    }
}
